var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventList"},[_c('Grid',{ref:_vm.kgm_ref,style:({ height: 'auto' }),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.eventsCopy),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.eventsCopy),"sortable":{
      allowUnsort: _vm.kgm_allowUnsort,
      mode: _vm.kgm_sortMode,
    },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":function($event){_vm.kgm_gridOnRowClick, _vm.onSelectionChange},"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange,"selectionchange":_vm.onSelectionChange,"headerselectionchange":_vm.onHeaderSelectionChange},scopedSlots:_vm._u([{key:"textFilter",fn:function(ref){
    var props = ref.props;
    var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"textFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter an value"},domProps:{"value":props.value},on:{"input":function (event) {
            methods.change({
              operator: 'contains',
              field: props.field,
              value: event.target.value,
              syntheticEvent: event,
            });
          }}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('textFilterInput', function (event) {
              methods.change({
                operator: '',
                field: '',
                value: '',
                syntheticEvent: event,
              });
            })}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }