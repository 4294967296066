var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventsCalendar"},[_c('FullCalendar',{ref:"calendar",attrs:{"options":_vm.calendarOptions,"locale":"de"},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [(arg.event.id.startsWith('ROLL-'))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(arg.event.title),expression:"arg.event.title"}],staticClass:"fc-event-main-frame"},[_c('div',{staticClass:"fc-event-title-container"},[_c('div',{staticClass:"fc-event-title fc-sticky"},[_vm._v(" "+_vm._s(arg.event.title)+" ")])])]):(arg.event.id == 'Holiday')?_c('div',{staticClass:"fc-event-main-frame"},[_c('div',{staticClass:"fc-event-title-container"},[_c('div',{staticClass:"fc-event-title fc-sticky"},[_vm._v(" "+_vm._s(arg.event.title)+" ")])])]):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.getTooltip(arg.event.id),
          classes: 'pre-line-tltp',
        }),expression:"{\n          content: getTooltip(arg.event.id),\n          classes: 'pre-line-tltp',\n        }"}],staticClass:"fc-event-main-frame"},[_c('div',{staticClass:"fc-event-title-container"},[_c('div',{staticClass:"fc-event-title fc-sticky"},[_vm._v(" "+_vm._s(arg.event.title)+" ")])])])]}}])}),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":function($event){_vm.showSidebar = false}}},[(_vm.showSidebar)?_c(_vm.currentComponent,{tag:"component",attrs:{"date":_vm.selectedDateRange,"event-id":_vm.eventId},on:{"reloadEvents":function($event){_vm.getCalendarData();
        _vm.showSidebar = false;}}}):_vm._e()],1),_c('SweetModal',{ref:"chooseModal",attrs:{"title":"Choose","blocking":"","width":"20%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('button',{staticClass:"btn btn-primary mr-3",on:{"click":function($event){return _vm.openEditSidebar()}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('button',{staticClass:"btn btn-secondary mr-3",on:{"click":function($event){return _vm.viewEvent()}}},[_vm._v(" "+_vm._s(_vm.$t("view"))+" ")]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.openDeleteEvent()}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.chooseModal.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t("cancel"))+" ")],1),_c('div',{staticClass:"clearfix"})]),_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":"Delete event?"},on:{"close":function($event){_vm.openDeleteModal = false}}},[_c('p',[_vm._v("Are you sure you want to delete this Event?")]),(_vm.eventLoading)?_c('LoadingPlaceholder'):(
        _vm.selectedEvent &&
          _vm.selectedEvent.commonInstallationEvents &&
          _vm.selectedEvent.commonInstallationEvents.length
      )?[_c('div',{staticClass:"form-check form-check-inline mb-3 mr-5",staticStyle:{"width":"100%","justify-content":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deleteOtherEvents),expression:"deleteOtherEvents"}],staticClass:"form-check-input",attrs:{"id":"deleteOtherEvents","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.deleteOtherEvents)?_vm._i(_vm.deleteOtherEvents,null)>-1:(_vm.deleteOtherEvents)},on:{"change":function($event){var $$a=_vm.deleteOtherEvents,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.deleteOtherEvents=$$a.concat([$$v]))}else{$$i>-1&&(_vm.deleteOtherEvents=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.deleteOtherEvents=$$c}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"deleteOtherEvents"}},[_vm._v("Do you want to delete the linked Events?")])]),(_vm.openDeleteModal && _vm.deleteOtherEvents)?_c('InstallationEventSelectList',{attrs:{"events":_vm.selectedEvent.commonInstallationEvents,"selected":_vm.selectedEvents},on:{"setSelectedList":function($event){_vm.selectedEvents = $event}}}):_vm._e()]:_vm._e(),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){_vm.$refs.confirmDelete.close();
        _vm.openDeleteModal = false;}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t("cancel")))])],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button","disabled":_vm.eventLoading},on:{"click":function($event){return _vm.removeEvent()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])],1),_c('div',{staticClass:"clearfix"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }