<template>
  <!--
    Calendar for Events
  -->
  <div class="eventsCalendar">
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      locale="de"
    >
      <template v-slot:eventContent="arg">
        <div
          v-if="arg.event.id.startsWith('ROLL-')"
          v-tooltip="arg.event.title"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
        <div
          v-else-if="arg.event.id == 'Holiday'"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
        <div
          v-else
          v-tooltip="{
            content: getTooltip(arg.event.id),
            classes: 'pre-line-tltp',
          }"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
      </template>
    </FullCalendar>
    <Sidebar
      :show-sidebar="showSidebar"
      @close="showSidebar = false"
    >
      <component
        :is="currentComponent"
        v-if="showSidebar"
        :date="selectedDateRange"
        :event-id="eventId"
        @reloadEvents="
          getCalendarData();
          showSidebar = false;
        "
      />
    </Sidebar>
    <SweetModal
      ref="chooseModal"
      title="Choose"
      blocking
      width="20%"
    >
      <div style="display: flex; justify-content: center">
        <button
          class="btn btn-primary mr-3"
          @click="openEditSidebar()"
        >
          {{ $t("edit") }}
        </button>
        <button
          class="btn btn-secondary mr-3"
          @click="viewEvent()"
        >
          {{ $t("view") }}
        </button>
        <button
          class="btn btn-danger"
          @click="openDeleteEvent()"
        >
          {{ $t("delete") }}
        </button>
      </div>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.chooseModal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete event?"
      class="overflowHidden"
      @close="openDeleteModal = false"
    >
      <p>Are you sure you want to delete this Event?</p>
      <LoadingPlaceholder v-if="eventLoading" />
      <template
        v-else-if="
          selectedEvent &&
            selectedEvent.commonInstallationEvents &&
            selectedEvent.commonInstallationEvents.length
        "
      >
        <div
          class="form-check form-check-inline mb-3 mr-5"
          style="width: 100%; justify-content: center"
        >
          <input
            id="deleteOtherEvents"
            v-model="deleteOtherEvents"
            class="form-check-input"
            type="checkbox"
          />
          <label
            class="form-check-label"
            for="deleteOtherEvents"
          >Do you want to delete the linked Events?</label>
        </div>
        <InstallationEventSelectList
          v-if="openDeleteModal && deleteOtherEvents"
          :events="selectedEvent.commonInstallationEvents"
          :selected="selectedEvents"
          @setSelectedList="selectedEvents = $event"
        />
      </template>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="
          $refs.confirmDelete.close();
          openDeleteModal = false;
        "
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t("cancel") }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        :disabled="eventLoading"
        @click="removeEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t("delete") }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { urlParseMixin } from "@/mixins/urlParseMixin";
import { mapGetters } from "vuex";
import AddInstallationEventCalendar from "@/components/Installation/AddInstallationEventCalendar.vue";
import EditInstallationEventCalendar from "@/components/Installation/EditInstallationEventCalendar.vue";
import Sidebar from "@/components/Base/Sidebar.vue";
import { SweetModal } from "sweet-modal-vue";
import InstallationEventSelectList from "../Installation/InstallationEventSelectList.vue";

export default {
  name: "EventCalendar",
  components: {
    FullCalendar,
    AddInstallationEventCalendar,
    EditInstallationEventCalendar,
    Sidebar,
    SweetModal,
    InstallationEventSelectList,
  },
  mixins: [dateTimeMixin, urlParseMixin],
  props: {
    excludedTypes: {
      type: Array,
      required: true,
    },
    eventTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        firstDay: 1,
        locale: "en",
        editable: false,
        selectable: true,
        selectMirror: true,
        weekNumbers: true,
        datesSet: this.handleDatesSet,
        eventClick: this.handleEventClick,
        select: this.handleDateSelect,
        events: this.filteredEvents,
        weekText: "KW",
        eventDidMount: this.eventMounted,
        dayMaxEvents: 5,
        height: "auto",
      },
      filteredEvents: [],
      events: [],
      startDate: null,
      endDate: null,
      selectedDateRange: null,
      colors: [
        "#e6194B",
        "#3cb44b",
        "#4363d8",
        "#f58231",
        "#911eb4",
        "#42d4f4",
        "#f032e6",
        "#bfef45",
        "#fabed4",
        "#469990",
        "#dcbeff",
        "#9A6324",
        "#fffac8",
        "#800000",
        "#aaffc3",
        "#808000",
        "#ffd8b1",
        "#000075",
        "#a9a9a9",
      ],
      usedColors: [],
      showSidebar: false,
      currentComponent: null,
      selectedEvent: null,
      openDeleteModal: false,
      eventLoading: false,
      deleteOtherEvents: false,
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
    eventId() {
      return this.selectedEvent ? this.selectedEvent.id : null
    } 
  },
  watch: {
    "$i18n.locale"() {
      this.calendarOptions.locale = this.$i18n.locale;
    },
    excludedTypes() {
      if (this.excludedTypes.length == 0) {
        this.filteredEvents = this.events.filter(
          (x) =>
            !this.excludedTypes.some((e) => e.name == x.type) ||
            x.type == "Holiday"
        );
      } else {
        this.filteredEvents = this.events.filter(
          (x) =>
            this.excludedTypes.some((e) => e.name == x.type) ||
            x.type == "Holiday"
        );
      }

      this.calendarOptions.events = this.filteredEvents;
    },
  },
  created() {
    if (this.$i18n.locale == "de") {
      this.calendarOptions.locale = deLocale.code;
    }
    this.getCalendarData();
  },
  methods: {
    viewEvent() {
      this.$refs.chooseModal.close();
      let routeData = this.$router.resolve({
        path: `/installation/${this.selectedEvent.installationId}/event`,
      });
      window.open(routeData.href, "_blank");
    },
    async openEditSidebar() {
      this.$refs.chooseModal.close();
      this.showSidebar = true;
      this.currentComponent = "EditInstallationEventCalendar";
    },
    openDeleteEvent() {
      this.$refs.chooseModal.close();
      this.deleteOtherEvents = false;
      this.$refs.confirmDelete.open();
      this.openDeleteModal = true;
      this.getInstallationEvent();
    },
    handleDateSelect(selectInfo) {
      this.selectedDateRange = selectInfo;
      this.currentComponent = "AddInstallationEventCalendar";
      this.showSidebar = true;
    },
    handleDatesSet(dateInfo) {
      if (!this.startDate) {
        this.startDate = dateInfo.start;
        this.endDate = dateInfo.end;
        this.getCalendarData();
      } else {
        let utcDateInfoStart = this.toMoment(dateInfo.start).utc();
        let utcDateStart = this.toMoment(this.startDate).utc();
        if (utcDateInfoStart.format() != utcDateStart.format()) {
          this.startDate = dateInfo.start;
          this.endDate = dateInfo.end;
          this.getCalendarData();
        }
      }
    },
    handleEventClick(clickInfo) {
      if (
        clickInfo.event.id.startsWith("ROLL-") ||
        clickInfo.event.id.startsWith("EPIC-")
      ) {
        const link = document.createElement("a");
        const id = clickInfo.event.id.startsWith("EPIC-")
          ? clickInfo.event.id.replace("EPIC-", "")
          : clickInfo.event.id;
        link.href = this.urlParseMixin_combine(
          this.store_getJiraUrl,
          `browse/${id}`
        );
        link.target = "_blank";
        link.click();
      } else if (clickInfo.event.id != "Holiday") {
        this.selectedEvent = {
          installationId: clickInfo.event.id.split(";")[1],
          id: clickInfo.event.id.split(";")[0],
          name: clickInfo.event.title,
        };
        this.$refs.chooseModal.open();
      }
    },
    getTooltip(id) {
      let item = this.events.find((x) => x.id == id);
      if (item && item.description) {
        return `${item.name} \n\n <span class="nowrap">${
          item.description.length > 100
            ? item.description.substring(0, 200) + "..."
            : item.description
        }</span>`;
      } else if (item.id.startsWith("EPIC-")) {
        return `<span class="nowrap">${
          item.title.length > 100
            ? item.title.substring(0, 200) + "..."
            : item.title
        }</span>`;
      }
      return item.name;
    },
    async getInstallationEvent() {
      this.eventLoading = true;
      this.axios
        .get(
          `/Installation/GetInstallationEvent?eventId=${this.selectedEvent.id}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.selectedEvent = response.data;
          if (this.selectedEvent.commonInstallationEvents) {
            this.selectedEvents =
              this.selectedEvent.commonInstallationEvents.filter((x) => {
                return x.selected;
              });
          } else {
            this.selectedEvents = [];
          }
        })
        .finally(() => {
          this.eventLoading = false;
        });
    },
    async removeEvent() {
      this.$emit("setLoading", true);
      if (!this.deleteOtherEvents) {
        this.selectedEvents = [];
      }
      this.$refs.confirmDelete.close();
      await this.axios
        .post(
          `/Freshdesk/RemoveInstallationEvent?eventId=${this.selectedEvent.id}&statusId=${this.selectedEvent.statusId}`,
          this.selectedEvents
        )
        .then(() => {
          this.$snotify.success(
            this.$t("installationEvent.deletedSuccessfully")
          );
        })
        .finally(() => {
          this.selectedEvent = null;
          this.selectedEvents = [];
          this.getCalendarData();
        });
    },
    getCalendarData() {
      this.$emit("setLoading", true);
      this.axios
        .get(
          `/Installation/GetInstallationEventsByDate?start=${this.dateTime_isoString(
            this.startDate
          )}&end=${this.dateTime_isoString(this.endDate)}`
        )
        .then((response) => {
          if (response && response.data) {
            this.filteredEvents = [];
            this.events = [];
            response.data.forEach((el) => {
              let usedColor = this.usedColors.find(
                (x) => x.type == el.eventType
              );
              if (!usedColor) {
                let unusedColor = this.colors.find(
                  (x) => !this.usedColors.some((t) => t.color == x)
                );
                if (unusedColor) {
                  usedColor = { type: el.eventType, color: unusedColor };
                  this.usedColors.push(usedColor);
                }
              }

              this.events.push({
                id:
                  el.eventType === "Go-Live" ||
                  (el.installationId && el.installationId.startsWith("EPIC-"))
                    ? el.installationId
                    : el.eventType == "Holiday"
                    ? "Holiday"
                    : `${el.id};${el.installationId}`,
                title: el.installationName,
                start: el.startDate,
                end: el.endDate,
                type: el.eventType,
                allDay: true,
                color: el.color ? el.color : usedColor.color,
                textColor: el.name,
                description: el.description,
                name: el.name,
              });
            });

            let allEventTypes = [
              ...new Set(
                this.events
                  .filter((x) => x.type != "Holiday")
                  .map((x) => JSON.stringify({ name: x.type, color: x.color }))
              ),
            ].map(JSON.parse);
            this.$emit("setEventTypes", allEventTypes);

            if (this.excludedTypes.length == 0) {
              this.filteredEvents = this.events.filter(
                (x) =>
                  !this.excludedTypes.some((e) => e.name == x.type) ||
                  x.type == "Holiday"
              );
            } else {
              this.filteredEvents = this.events.filter(
                (x) =>
                  this.excludedTypes.some((e) => e.name == x.type) ||
                  x.type == "Holiday"
              );
            }

            this.calendarOptions.events = this.filteredEvents;
          }
        })
        .finally(() => {
          this.$emit("setLoading", false);
        });
    },
  },
};
</script>

<style>
.fc-h-event .fc-event-main {
  cursor: pointer;
}

.pre-line-tltp .tooltip-inner {
  white-space: pre;
}
.pre-line-tltp .tooltip-inner .nowrap {
  white-space: normal;
}
</style>
