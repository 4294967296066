<template>
  <div class="editInstallationEventCalendar">
    <LoadingPlaceholder v-show="blockButton || eventLoading" />
    <template v-if="!eventLoading">
      <form v-show="!blockButton">
        <div class="form-group">
          <h4 class="m-0 mt-3">
            Installation Event
          </h4>
          <hr class="m-0 mb-3" />
          <label>Name</label>
          <input
            v-model="installationEvent.name"
            v-focus
            :class="['form-control']"
            type="text"
          />
          <hr class="m-0 mb-3" />
          <label>{{ $t("eventType") }}</label>
          <LoadingPlaceholder v-if="loading" />
          <ComboBox
            v-else
            v-model="installationEvent.eventType"
            :data-source="eventTypes"
            :placeholder="'Select a event type...'"
            class="form-control"
          />
          <div class="m-0 mb-3 mt-3" />
          <div class="form-check form-check-inline mb-3 mr-5">
            <input
              id="executeAction"
              v-model="installationEvent.executeAction"
              class="form-check-input"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="executeAction"
            >Automatically disable Monitoring during Event</label>
          </div>
          <div class="form-check form-check-inline mb-3 mr-5">
            <input
              id="switchToMaintenanceMode"
              v-model="installationEvent.switchToMaintenanceMode"
              class="form-check-input"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="switchToMaintenanceMode"
            >Automatically activate maintenance mode / page during
              event</label>
          </div>
          <label>{{ $t("startDate") }}</label>
          <date-picker
            v-model="installationEvent.startDate"
            :editable="true"
            :clearable="false"
            type="datetime"
            lang="en"
            format="DD.MM.YYYY HH:mm"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <div class="m-0 mb-3" />
          <label>{{ $t("endDate") }}</label>
          <date-picker
            v-model="installationEvent.endDate"
            :editable="true"
            :clearable="false"
            type="datetime"
            lang="en"
            format="DD.MM.YYYY HH:mm"
            value-type="date"
            :first-day-of-week="1"
            class="ml-4"
          />
          <div class="m-0 mb-3" />
          <label>{{ $t("description") }}</label>
          <textarea
            v-model="installationEvent.description"
            class="form-control m-input m-input--air"
            name="Description"
            rows="5"
            placeholder="Please enter your description"
          />
          <div class="m-0 mb-3" />
          <template>
            <label
              for="keycloakUsers"
              class="form-label"
            >User to notify (will be notified 5 min before start)</label>
            <LoadingPlaceholder v-if="userLoading" />
            <Multiselect
              v-else-if="keycloakUsers"
              id="keycloakUsers"
              v-model="selectedKeycloakUsers"
              tag-placeholder="Not found"
              placeholder="Select User"
              :options="keycloakUsers"
              :custom-label="customLabel"
              :multiple="true"
              :taggable="true"
              track-by="email"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
            <label
              for="notifyDescription"
              class="form-label"
            >Notify description</label>
            <textarea
              v-model="installationEvent.notifyDescription"
              class="form-control m-input m-input--air"
              name="notifyDescription"
              rows="5"
              placeholder="If this field is left empty, the Event description, will be sent."
            />
            <div class="m-0 mb-3" />
            <template
              v-if="
                installationEvent.commonId &&
                  installationEvent.commonInstallationEvents &&
                  installationEvent.commonInstallationEvents.length
              "
            >
              <div class="form-check form-check-inline mb-3 mr-5">
                <input
                  id="editOtherInstallations"
                  v-model="editOtherInstallations"
                  class="form-check-input"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="editOtherInstallations"
                >Do you want to edit the linked Events?</label>
              </div>
              <div
                v-if="editOtherInstallations"
                class="d-flex"
                style="justify-content: space-between"
              >
                <p class="mt-2">
                  Linked Events: {{ selectedEvents.length }}
                </p>
                <button
                  class="btn btn-primary float-right"
                  @click.prevent="openModal()"
                >
                  Events
                </button>
              </div>
            </template>
          </template>
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="
            !installationEvent.eventType ||
              !installationEvent.startDate ||
              !installationEvent.endDate ||
              blockButton
          "
          @click.prevent="editInstallationEvent()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />{{ $t("edit") }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
    <SweetModal
      ref="eventModal"
      title="Events"
      blocking
      @close="openSelectionModal = false"
    >
      <InstallationEventSelectList
        v-if="openSelectionModal"
        :events="installationEvent.commonInstallationEvents"
        :selected="selectedEvents"
        @setSelectedList="tempSelectedEvents = $event"
      />
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="
          $refs.eventModal.close();
          openSelectionModal = false;
        "
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <button
        slot="button"
        class="btn btn-success float-right mb-3"
        @click="confirm"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />Confirm
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { ComboBox } from "@progress/kendo-dropdowns-vue-wrapper";
import { authenticationMixin } from "@/mixins/authenticationMixin";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import InstallationEventSelectList from "./InstallationEventSelectList.vue";
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "EditInstallationEventCalendar",
  components: {
    ComboBox,
    DatePicker,
    Multiselect: () => import("vue-multiselect"),
    SweetModal,
    InstallationEventSelectList,
  },
  mixins: [authenticationMixin, kendoGridMixin],
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      eventTypes: [],
      installationEvent: null,
      userLoading: false,
      keycloakUsers: null,
      selectedKeycloakUsers: [],
      blockButton: false,
      editOtherInstallations: false,
      selectedEvents: [],
      tempSelectedEvents: [],
      openSelectionModal: false,
      eventLoading: false,
      serviceId: null,
    };
  },
  async created() {
    this.installationEvent = {};
    this.getInstallationEvent();
    this.getEventTypes();
  },
  methods: {
    customLabel({ name, email }) {
      if (name) {
        return name;
      }
      if (email) {
        return email;
      }
      return "";
    },
    openModal() {
      this.$refs.eventModal.open();
      this.openSelectionModal = true;
      if (
        this.installationEvent &&
        this.installationEvent.commonInstallationEvents
      ) {
        this.installationEvent.commonInstallationEvents.forEach((event) => {
          if (this.selectedEvents.some((x) => x.id === event.id)) {
            event.selected = true;
          }
        });

        this.tempSelectedEvents = this.kgm_computedAllGridItems(
          this.installationEvent.commonInstallationEvents
        ).filter((x) => {
          return x.selected;
        });
      }
    },
    confirm() {
      this.selectedEvents = [...this.tempSelectedEvents];
      this.$refs.eventModal.close();
      this.openSelectionModal = false;
    },
    getUsers() {
      this.userLoading = true;
      this.axios
        .get("/User/GetKeycloakUsers")
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.keycloakUsers = response.data;
          if (this.installationEvent.usersToNotify) {
            const emails = this.installationEvent.usersToNotify
              .split(";")
              .map((email) => email.trim())
              .filter((email) => email);
            this.selectedKeycloakUsers = this.keycloakUsers.filter((x) =>
              emails.includes(x.email)
            );
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    async getEventTypes() {
      this.loading = true;
      await this.axios
        .get("/Installation/GetInstallationEventTypes")
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.eventTypes = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async editInstallationEvent() {
      this.blockButton = true;
      this.installationEvent.usersToNotify = this.selectedKeycloakUsers
        .map((x) => x.email)
        .join(";");
      if (!this.installationEvent.notifyDescription) {
        this.installationEvent.notifyDescription =
          this.installationEvent.description;
      }
      if (this.editOtherInstallations) {
        this.installationEvent.commonInstallationEvents = this.selectedEvents;
      } else {
        this.installationEvent.commonInstallationEvents = null;
      }
      await this.axios
        .put(
          `/Freshdesk/EditInstallationEvent?serviceId=${this.serviceId}`,
          this.installationEvent
        )
        .then((res) => {
          if (res) {
            this.blockButton = false;
            this.$snotify.success(
              this.$t("installationEvent.updatedSuccessfully")
            );
            this.$emit("reloadEvents");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
      this.blockButton = false;
    },
    getInstallationEvent() {
      this.eventLoading = true;
      this.axios
        .get(`/Installation/GetInstallationEvent?eventId=${this.eventId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationEvent = response.data;
          this.selectedEvents = [
            ...this.installationEvent.commonInstallationEvents
          ];
          this.tempSelectedEvents = [
            ...this.installationEvent.commonInstallationEvents,
          ];
        })
        .finally(() => {
          this.getUsers();
          this.eventLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.editInstallationEventCalendar .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
